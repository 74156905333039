import { render, staticRenderFns } from "./deprecated_modal.vue?vue&type=template&id=77ed7938&"
import script from "./deprecated_modal.vue?vue&type=script&lang=js&"
export * from "./deprecated_modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "deprecated_modal.vue"
export default component.exports